import { type RouteRecordRaw } from 'vue-router';

const FtpConfiguration: Array<RouteRecordRaw> = [
  {
    path: '/configuration/ftp-configuration',
    name: 'PageFtpConfiguration',
    component: () => import('@/views/ftp/FtpList.vue'),
    meta: {
      title: 'Ftp configurations',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/configuration/ftp-configuration/create-sending',
    name: 'CreateSendingFtp',
    component: () => import('@/views/ftp/server/CreateSending.vue'),
    meta: {
      title: 'Create sending FTP connection',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/configuration/ftp-configuration/create-receiving',
    name: 'CreateReceivingFtp',
    component: () => import('@/views/ftp/client/CreateReceiving.vue'),
    meta: {
      title: 'Create receiving FTP connection',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/configuration/ftp-configuration/client/:reference',
    name: 'UpdateClientFtpConnection',
    component: () => import('@/views/ftp/server/CreateSending.vue'),
    meta: {
      title: 'Update FTP connection',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/configuration/ftp-configuration/server/:reference',
    name: 'UpdateServerFtpConnection',
    component: () => import('@/views/ftp/client/CreateReceiving.vue'),
    meta: {
      title: 'Update FTP connection',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/configuration/ftp-configuration/client/:reference/logs',
    name: 'FTPClientLogs',
    component: () => import('@/views/ftp/client/ClientLogs.vue'),
    meta: {
      title: 'FTP client logs',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/configuration/ftp-configuration/server/:reference/logs',
    name: 'FTPServerLogs',
    component: () => import('@/views/ftp/server/ServerLogs.vue'),
    meta: {
      title: 'FTP server logs',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/configuration/ftp-configuration/credentials-request',
    name: 'RequestCredentials',
    component: () => import('@/views/ftp/CredentialsRequest.vue'),
    meta: {
      title: 'Request FTP credentials',
      bodyClass: 'legacy-form',
      allowAnonymous: true,
    },
  },
];

export default FtpConfiguration;
