<script lang="ts" setup>
import { IconComponent } from '@shipitsmarter/viya-ui-warehouse';
import { cn } from '@shipitsmarter/viya-ui-warehouse';
import type { HTMLAttributes } from 'vue';

const props = defineProps<{
  class?: HTMLAttributes['class'];
}>();
</script>

<template>
  <li role="presentation" aria-hidden="true" :class="cn('[&>svg]:size-3.5', props.class)">
    <slot>
      <IconComponent name="PhCaretRight" />
    </slot>
  </li>
</template>
