<template>
  <TooltipComponent
    v-if="(props.devMode && devModeOn) || !props.devMode"
    :content="props.label"
    direction="right"
    :hidden="showLabel"
    :delay="700"
  >
    <RouterLink
      @click="$emit('router-click')"
      :style="index ? { transitionDelay: index * 30 + 'ms' } : ''"
      :to="props.link"
      active-class="!text-accent bg-gray-300"
      v-bind="$attrs"
      :class="{ 'inline-block': !showLabel, '': configMenu }"
      class="flex items-center gap-3 rounded-lg px-3 py-3 text-muted hover:bg-gray-300 hover:text-accent hover:no-underline"
    >
      <IconComponent :name="props.icon" size="xl" />
      <span v-if="showLabel" class="grow font-semibold">
        {{ props.label }}
        <span v-if="betaMode" class="rounded-xl bg-blue-500 p-0.5 px-2 text-xs text-white">
          Beta
        </span>
      </span>
    </RouterLink>
  </TooltipComponent>
</template>
<script setup lang="ts">
import { IconComponent, IconName, TooltipComponent } from '@shipitsmarter/viya-ui-warehouse';

import { useContextStore } from '@/store';

interface NavSideBarButtonEmits {
  (event: 'router-click'): void;
}

defineEmits<NavSideBarButtonEmits>();

const props = defineProps<{
  link: string;
  icon: IconName;
  label: string;
  showLabel?: boolean;
  devMode?: boolean;
  betaMode?: boolean;
  index?: number;
  class?: string;
  configMenu?: boolean;
}>();

const store = useContextStore();
const devModeOn = store.getDevMode;
</script>
