import { type RouteRecordRaw } from 'vue-router';

import { type AccessMeta } from '@/services/decision';

const Shipments: Array<RouteRecordRaw> = [
  {
    path: '/shipments/:page?/:rows?',
    name: 'Shipments',
    component: () => import('@/views/shipment/overview/ShipmentOverview.vue'),
    meta: {
      title: 'Shipments',
      bodyClass: 'legacy-form',
      access: {
        url: 'shipments',
        method: 'GET',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },
  {
    path: '/test/shipments/:page?/:rows?',
    name: 'TestShipments',
    component: () => import('@/views/shipment/overview/ShipmentOverview.vue'),
    meta: {
      title: 'Shipments',
      testMode: true,
      bodyClass: 'legacy-form',
      access: {
        url: 'shipments',
        method: 'GET',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },
  {
    path: '/shipments/:id',
    name: 'Shipment',
    component: () => import('@/views/shipment/details/ShipmentPage.vue'),
    props: true,
    meta: {
      title: 'Shipment',
      access: {
        url: 'shipments/:id',
        method: 'GET',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },
  {
    path: '/test/shipments/:id',
    name: 'TestShipment',
    component: () => import('@/views/shipment/details/ShipmentPage.vue'),
    props: true,
    meta: {
      title: 'Shipment',
      testMode: true,
      access: {
        url: 'shipments/:id',
        method: 'GET',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },
  {
    path: '/shipments/:id/edit',
    name: 'Edit shipment',
    component: () => import('@/views/shipment/create/ShipmentCreate.vue'),
    props: {
      mode: 'edit',
    },
    meta: {
      title: 'Edit shipment',
      access: {
        url: 'shipments/:id/edit',
        method: 'GET',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },
  {
    path: '/test/shipments/:id/edit',
    name: 'Edit test shipment',
    component: () => import('@/views/shipment/create/ShipmentCreate.vue'),
    props: {
      mode: 'edit',
    },
    meta: {
      title: 'Edit test shipment',
      testMode: true,
      access: {
        url: 'shipments/:id/edit',
        method: 'GET',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },
  {
    path: '/shipments/create',
    name: 'ShipmentCreate',
    component: () => import('@/views/shipment/create/ShipmentCreate.vue'),
    props: {
      mode: 'create',
    },
    meta: {
      title: 'Create a new shipment',
      access: {
        url: 'shipments',
        method: 'POST',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },
  {
    path: '/test/shipments/create',
    name: 'TestShipmentCreate',
    component: () => import('@/views/shipment/create/ShipmentCreate.vue'),
    props: {
      mode: 'create',
    },
    meta: {
      title: 'Create a new shipment',
      testMode: true,
      access: {
        url: 'shipments',
        method: 'POST',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },

  // Shareable public pages: Order Request (for carriers) and Shipment Tracking (for receivers/end-customers)
  {
    path: '/order-request',
    name: 'OrderPage',
    component: () => import('@/views/shipment/details/OrderRequestPage.vue'),
    meta: {
      title: 'Request for a new Shipment Order',
      allowAnonymous: true,
    },
  },
  {
    path: '/track-shipment',
    name: 'Shipment Tracking Page',
    component: () => import('@/views/shipment/details/ShipmentTrackingPage.vue'),
    meta: {
      title: 'Track your Shipment',
      allowAnonymous: true,
    },
  },
];

export default Shipments;
