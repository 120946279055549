import { defineStore } from 'pinia';

import { ApiClient, isOk } from '@/generated/clients';
import { type components as printing } from '@/generated/printing';

import { FeatureFlag, useFeatureFlagsStore } from './__tests__/feature-flags.ts';
type Printer = printing['schemas']['Printer'];

export const usePrinterStore = defineStore('printers', {
  state: () => ({
    printers: [] as Printer[],
  }),

  actions: {
    async tryGetPrinters() {
      const featureFlagStore = useFeatureFlagsStore();
      if (featureFlagStore.isFlagEnabled(FeatureFlag.PrintNode)) {
        const res = await ApiClient.GET('/printing/v1/printers');
        if (!isOk(res) || !res.data) {
          console.warn('Failed to fetch printers');
          return;
        }
        this.printers = res.data.items as Printer[];
      }
    },
  },
});
