import { type RouteRecordRaw } from 'vue-router';

const Rates: Array<RouteRecordRaw> = [
  {
    path: '/rates/load',
    name: 'RatesConfiguration',
    component: () => import('@/views/rates/configuration/RatesConfiguration.vue'),
    props: true,
    meta: {
      title: 'Import contract data',
    },
  },
  {
    path: '/rates/create-service-level',
    name: 'CreateServiceLevel',
    component: () => import('@/views/rates/service-levels/EditServiceLevelForm.vue'),
    props: {
      new: true,
    },
    meta: {
      title: 'Create new service level',
    },
  },
  {
    path: '/rates/update-service-level',
    name: 'UpdateServiceLevel',
    component: () => import('@/views/rates/service-levels/EditServiceLevelForm.vue'),
    props: {
      new: false,
    },
    meta: {
      title: 'Update service level',
    },
  },
];

export default Rates;
