import { _GettersTree, defineStore } from 'pinia';

import { ApiClient, isOk } from '@/generated/clients.ts';

// This store is for determining feature flags set by our system. Not to be confused with features, that can be managed by users.

export enum FeatureFlag {
  PrintNode = 'printnode',
}

interface State {
  isInitialised: boolean;
  flags: {
    [key in FeatureFlag]: boolean;
  };
}

interface Getters extends _GettersTree<State> {
  isFlagEnabled: (state: State) => (featureFlag: FeatureFlag) => boolean;
}

interface Actions {
  initialise: () => Promise<void>;
  refresh: () => Promise<void>;
}

const defaultState: () => State = () => {
  return {
    isInitialised: false,
    flags: {
      [FeatureFlag.PrintNode]: false,
    },
  };
};

export const useFeatureFlagsStore = defineStore<string, State, Getters, Actions>('feature-flags', {
  state: defaultState,
  getters: {
    isFlagEnabled: (state) => (featureFlag) => state.isInitialised && state.flags[featureFlag],
  },
  actions: {
    async initialise() {
      if (this.isInitialised) {
        return Promise.resolve();
      }
      await this.refresh();
      this.isInitialised = true;
    },
    async refresh() {
      await Promise.all(
        Object.values(FeatureFlag).map(async (featureFlag) => {
          const res = await ApiClient.GET('/configs/v1/feature-flags/{reference}', {
            params: { path: { reference: featureFlag } },
          });

          if (isOk(res) && res.data) {
            this.flags[featureFlag] = true;
          }
        })
      );
    },
  },
});
