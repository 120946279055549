<script setup lang="ts">
import { cn } from '@shipitsmarter/viya-ui-warehouse';
import { DropdownMenuTrigger, type DropdownMenuTriggerProps, useForwardProps } from 'radix-vue';

const props = defineProps<DropdownMenuTriggerProps>();

const forwardedProps = useForwardProps(props);
</script>

<template>
  <DropdownMenuTrigger
    :class="cn('outline-none data-[state=open]:text-teal-600')"
    v-bind="forwardedProps"
  >
    <slot />
  </DropdownMenuTrigger>
</template>
