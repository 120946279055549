import { type RouteRecordRaw } from 'vue-router';

const User: Array<RouteRecordRaw> = [
  {
    path: '/user/profile',
    name: 'UserProfile',
    component: () => import('@/views/user/UserProfile.vue'),
    props: true,
    meta: {
      title: 'User Profile',
    },
  },
  {
    path: '/user/management',
    name: 'UserManagement',
    component: () => import('@/views/user/UserManagement.vue'),
    props: true,
    meta: {
      title: 'User management',
    },
  },
  {
    path: '/user/management/create',
    name: 'UserCreate',
    component: () => import('@/views/user/UserEdit.vue'),
    props: true,
    meta: {
      title: 'Create new user',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/user/management/:reference',
    name: 'UserDetail',
    component: () => import('@/views/user/UserDetail.vue'),
    props: true,
    meta: {
      title: 'User',
    },
  },
  {
    path: '/user/management/:reference/edit',
    name: 'UserEdit',
    component: () => import('@/views/user/UserEdit.vue'),
    props: true,
    meta: {
      title: 'Edit user',
      bodyClass: 'legacy-form',
    },
  },
];

export default User;
